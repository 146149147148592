<template>
  <div>
    <h2 v-html="$t('search_label_orders_label_belonging')"></h2>
    <!-- Label Bundle -->
    <!-- :item-text="this.$getLangKey()" -->

    <v-select
      id="search_label_orders_label_bundle"
      v-model="searchCardStore.labelBundle"
      :label="$t('search_label_orders_label_bundle')"
      :items="labelBundles"
      item-title="name"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!-- Von -->
    <DbmDatePicker v-model="searchCardStore.minValidFrom" :dateLabel="$t('search_label_orders_valid_from')" :clearable="true" />
    <!-- Bis -->
    <DbmDatePicker v-model="searchCardStore.maxValidUntil" :dateLabel="$t('search_label_orders_valid_until')" :clearable="true" />
    <!-- Stichtag -->
    <DbmDatePicker v-model="searchCardStore.dueDate" :dateLabel="$t('search_label_orders_due_date')" :clearable="true" />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { useSearchLabelAffiliationStore } from '@/store/SearchLabelAffiliationStore'
import { useLabelBundleStore } from '@/store/enums/LabelBundleStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchLabelAffiliation',
  extends: baseSearchVue,
  data() {
    return {}
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  computed: {
    labelBundles() {
      return useLabelBundleStore().labelBundles
    }
  },
  beforeMount() {
    useLabelBundleStore().fetchLabelBundles()
    this.setSearchCardStore(useSearchLabelAffiliationStore(), true)
  }
})
</script>
