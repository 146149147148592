import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'
import { format } from 'date-fns'

export const useSearchLabelAffiliationStore = defineStore('searchLabelAffiliationStore', {
  state: () => ({
    labelBundle: null as number | null,
    minValidFrom: null as string | null,
    maxValidUntil: null as string | null,
    dueDate: format(new Date(), 'yyyy-MM-dd') as string | null,
    items: [
      { key: 'labelBundle', operation: ':', itemType: 'number' },
      { key: 'minValidFrom', operation: ':' },
      { key: 'maxValidUntil', operation: ':' },
      { key: 'dueDate', operation: ':', defaultValue: format(new Date(), 'yyyy-MM-dd') }
    ] as TermItemDefinition[]
  })
})
